* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.modal {
  position: fixed; /* Stay in place */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

html,
body {
  overflow-y: hidden;
  background-color: rgb(0, 0, 0);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.dashboard-container::-webkit-scrollbar,
.overflow-x-scroll::-webkit-scrollbar,
.scrolls::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.dashboard-container,
.overflow-x-scroll,
.scrolls {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.sidebar-container,
.overflow-x-scroll,
.scrolls {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.sidebar-container::-webkit-scrollbar,
.overflow-x-scroll::-webkit-scrollbar,
.scrolls::-webkit-scrollbar {
  display: none;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
